import React, { Component } from 'react';

// import AnchorLink from 'react-anchor-link-smooth-scroll';

import Layout from '../components/layout';
import SEO from "../components/seo";

import './casestudyequifax.scss';

import imgQuote from "../images/quotation_mark.webp";

// import SimpleModal from '../components/utilities/SimpleModal';

class OneImageFullTwo extends Component {
    render() {
        const divStyles = {
            backgroundImage: 'url('+this.props.imageOne+')',
            backgroundPosition: 'center center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            height: this.props.heightTwo,
            backgroundColor: this.props.backgroundColor
        };
        return (
            <div className="container-fluid one-image-wrapper p-0">
                <div className="row align-items-center no-gutters">
                    <div className="col-12 bgImg" style={divStyles}>

                    </div>
                </div>
            </div>
        );
    }
}

export default ({pageContext}) => (
    <Layout>
        <SEO 
            title={pageContext.yoast.title}
            description={pageContext.yoast.metadesc}
            ogimg={pageContext.yoast.opengraph_image}
            link={pageContext.link}
            />        
        <div className="case-study-one-wrapper">
            <div className="container-fluid">
                <div className="row">
                    <div className="header-container" style={{backgroundImage: `url(${pageContext.acf.header_image.csOneHedImaLocalFile.childImageSharp.fluid.src})`}}>
                        <img src={imgQuote} alt=""/>
                        <h2>{pageContext.acf.header_text}</h2>
                    </div>
                </div>
            </div>
            <div className="container detail-wrapper">
                <div className="row">
                    <div className="col-md-8">
                        <img src={pageContext.acf.client_logo.csOneCliLogLocalFile.childImageSharp.fluid.src} className="client-logo" width={pageContext.acf.client_logo.media_details.width/2} height={pageContext.acf.client_logo.media_details.height/2} alt={pageContext.yoast.opengraph_title} />
                    </div>
                    <div className="col-md-4">
                        <a href="https://www.meritmile.com/uploads/2024/01/merit-mile-equifax-case-study.pdf" className="btn downloadBtn" target="_blank" rel="nofollow noopener noreferrer" style={{ marginTop: '4em'}}>Download the PDF here</a>
                    </div>
                    <div className="col-12">
                        <div className="bar"></div>
                    </div>
                    <div className="col-12">
                        <div className="first-paragraph" dangerouslySetInnerHTML={{ __html: pageContext.acf.first_paragraph}} />
                    </div>
                    <div className="col-12"> 
                        {pageContext.acf.learn_more_url
                            ? <a href={pageContext.acf.learn_more_url} className={pageContext.acf.learn_more_url ? '' : 'd-none'} target="_blank" rel="noopener noreferrer"><div className="btn btn-grey">Learn More</div></a>
                            : <div className="d-none"><div className="btn btn-grey">Learn More</div></div>
                        }
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 order-2">
                        <div className="second-paragraph" dangerouslySetInnerHTML={{ __html: pageContext.acf.second_paragraph}} />
                        <div className="third-paragraph" dangerouslySetInnerHTML={{ __html: pageContext.acf.third_paragraph}} />
                    </div>
                    <div className="col-md-6 order-1 order-md-2">
                        <img src={pageContext.acf.desktop_image.csOneDesImaLocalFile.childImageSharp.fluid.src} className="desktop-image img-fluid" width={pageContext.acf.desktop_image.media_details.width} height={pageContext.acf.desktop_image.media_details.height} alt=""/>
                    </div>
                    <div className="col-12 order-3">
                        <div className="fourth-paragraph" dangerouslySetInnerHTML={{ __html: pageContext.acf.fourth_paragraph}} />
                    </div>
                </div>
            </div>
            <div className="container-fluid cta">
                <div className="row">
                    <div className="col-12">
                        <h2 className="ctaTitleOne">Start Creating Business Momentum Today</h2>
                        <a href="#footer" className="btn btn-light">Get In Touch</a>
                    </div>
                </div>
            </div>
            <OneImageFullTwo width={pageContext.childWordPressAcf1ImageFull.image.media_details.width/2} heightTwo={pageContext.childWordPressAcf1ImageFull.image.media_details.height/2} imageOne={pageContext.childWordPressAcf1ImageFull.image.source_url} backgroundColor={pageContext.childWordPressAcf1ImageFull.image_full_bg_color} />
            <div className="container view-website-btn">
                <div className="row">
                    <div className="col-12 text-center">
                        <a href="https://www.equifax.com/personal/" target="_blank" rel="noopener noreferrer">
                            <div className="btn btn-black">View Website</div>
                        </a>
                    </div>
                </div>
            </div>
            <div className="container quote-wrapper">
                <div className="row">
                    <div className="col-12">
                        <p>"We are becoming a world where data may be more important than software"</p>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
)